// import external dependencies
import barba from '@barba/core';
import is from 'is_js';
import imagesLoaded from './vendors/imagesloaded';

import { IS_PRODUCTION } from './constants';

// import main styles in dev mode only
if ( !IS_PRODUCTION ) {
  import("../styles/main.scss");
  import("../styles/debug.scss");
  import("./debug");
}

// import internal dependencies
import '@pwup/core/hello';
import { isTouchDevice } from '@pwup/core/is';
import DOM from '@pwup/dom/DOM';
import { query, queryAll } from '@pwup/dom/query';
import barbaScripts from '@pwup/utils/barba-scripts';
import BarbaWebpackChunks from '@pwup/utils/barba.webpack-chunks';
import MobileViewportUnit from '@pwup/utils/mobile-vh';

import Store from '@core/store';

barba.use(barbaScripts);

const BarbaWebpackChunksInstance = new BarbaWebpackChunks();
barba.use(BarbaWebpackChunksInstance);

import Transition from './ui/transition';


class App {
  constructor() {
    // Initialize UI
    this.transition = Transition.init();
  }

  _delayStart() { setTimeout(this.start.bind(this), 500); }
  _loadDeferScript() {
    [ ...queryAll('script[type="text/defer"]') ].forEach(script => {
      // create new script tag
      const tag = document.createElement('script');
            tag.id = script.id;
            tag.type = 'text/javascript';
            tag.src = script.src;

      DOM.body.appendChild(tag);
    });
  }
  _loadNewsletterUI() {
    if( Store.read('newsletter', false) === true ) return;
    
    // import module with webpack
    setTimeout(() => {
      import(`./ui/newsletter-popup/`).then(chunk => chunk.default.instance.init(BarbaWebpackChunksInstance.emitter));
    }, 2000);
  }

  load() {
    imagesLoaded( queryAll('[data-barba="container"] img:not([loading="lazy"])'), IS_PRODUCTION ? this.start.bind(this) : this._delayStart.bind(this) );
  }
  start() {
    barba.init({
      debug: !IS_PRODUCTION,
      timeout: IS_PRODUCTION ? 4000 : 8000,
      transitions: [{
        once: () => { return this.transition.appear(); },
        enter: () => { return this.transition.enter(); },
        leave: () => { return this.transition.leave(); },
        afterOnce: () => {
          this.transition.hide();

          this._loadDeferScript();
          this._loadNewsletterUI();
        },
        afterEnter: () => { this.transition.hide(); },
        beforeLeave: () => { this.transition.show(); },
        afterLeave: ({ current, next }) => {
          // remove current container from DOM
          current.container.parentNode.removeChild( current.container );

          // preload images from next container
          return new Promise(callback => {
            next.container ? imagesLoaded(queryAll('img:not([loading="lazy"])', next.container), callback) : callback();
          });
        },
      }],
      prevent: ({ href, el }) => {
        // if IE11, prevent barba
        if( is.ie() ) return true;

        // if el is from Snipcart UI, do nothing
        const snipcartMainElement = query('#snipcart');
        if( el && snipcartMainElement && snipcartMainElement.contains(el) ) return true;

        // if url change is only a empty hashing, do nothing
        if( href === '#' ) return true;
      },
    });
  }

  /*
  pause() {
    if( this.scroller ) this.scroller.stop();
    if( this.header ) this.header.hide();
    if( this.breadcrumb ) this.breadcrumb.pause();
  }
  resume() {
    if( this.scroller ) this.scroller.start();
    if( this.header ) this.header.show();
    if( this.breadcrumb ) this.breadcrumb.resume();
  }
  */
}

let app;

const start = () => {
  if( is.ios() ) DOM.html.classList.add('ios');
  if( is.android() ) DOM.html.classList.add('android');
  if( isTouchDevice ) MobileViewportUnit.init();

  app = new App();
  app.load();
};
const getApp = () => {
  return app;
};


// if DOM is already loaded, start immediatly
// otherwise, wait for DOMContentLoaded to start application
if( ['complete', 'interactive'].includes(document.readyState) ) start();
else document.addEventListener('DOMContentLoaded', start);

export default getApp;
